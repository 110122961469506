import iziToast from "izitoast"
import flatpickr from "flatpickr"
import { Italian } from "flatpickr/dist/l10n/it.js"
import MediumEditor from 'medium-editor'

const app = {
  initOnLoad: function() {
    app.initMediumEditor();

    app.initSortable();

    $('[data-rel="tooltip"]').tooltip({});

    $('[data-toggle="popover"]').popover({});

    $('[data-provide="flatpickr"]').each(function () {
      app.initFlatPickr($(this));
    });
  },

  initSortable: function(){
    $(".sortable").sortable(
      {
        tolerance: 'pointer',
        forcePlaceholderSize: true,
        opacity: 0.5,
        helper: function(event, ui){
          ui.children().each(function() {
            $(this).width($(this).width());
          });
          return ui;
        },
        update: function (event, ui) {
          var data = $(this).sortable('serialize');
          $.ajax({
            data: data,
            type: 'PUT',
            url: $(this).data('callback')
          });
        }
      }
    );
  },

  initMediumEditor: function(){
    new MediumEditor('.m-editor', {
      toolbar: {
        buttons: ['bold', 'italic', 'anchor']
      },
      placeholder: {
        text: 'Inserisci il contenuto...',
        hideOnClick: true
      }
    });
    new MediumEditor('.m-editor-modal', {
      toolbar: {
        buttons: ['bold', 'italic', 'anchor']
      },
      placeholder: {
        text: 'Inserisci il contenuto...',
        hideOnClick: true
      },
      elementsContainer: document.getElementById('ajax-modal-lg') // use your modal element here
    });
  },

  initFlatPickr: function($element){
    flatpickr($element, {
      locale: Italian,
      altInput: true,
      altFormat: "d/m/Y"
    });
  },

  flashMessage: function(name, msg, position){
    var obj = {
      message: msg,
      timeout: 8000,
      position: position
    }
    if(name == 'error'){
      obj.title = 'Errore'
      obj.color = 'red'
    }else if(name == 'notice'){
      obj.title = 'Successo'
      obj.color = 'green'
    }else if(name == 'alert'){
      obj.title = 'Attenzione'
      obj.color = 'yellow'
    }
    iziToast.show(obj);
  },

  availabilityGMapInit: function(availability_codes, editable, url) {
    let markers = []
    // Inizializzo la mappa di google
    var map = new google.maps.Map(document.getElementById('availability_map'), {
      zoom: 10,
      minZoom: 9,
      maxZoom: 13,
      center: {lat: 46, lng: 12.7},
      disableDefaultUI: true
    });

    map.addListener('zoom_changed', function(){
      markers.forEach(function(marker){
        let markerLabel = marker.getLabel();
        markerLabel.fontSize = mapHelper.getMarkerLabelSize(map);
        marker.setLabel(markerLabel);
      })
    });

    // creo i listeners per gli eventi
    map.data.addListener('addfeature', function(event) {
      let bounds = new google.maps.LatLngBounds();
      let feature = event.feature
      event.feature.getGeometry().forEachLatLng(function(latlng){
        bounds.extend(latlng)
      });
      let marker = mapHelper.addMarker(bounds.getCenter(), map, feature.getProperty('COMUNE'))
      markers.push(marker);
      feature.setProperty('marker', marker);
      if(availability_codes.indexOf(feature.getProperty('PRO_COM')) > -1){
        feature.setProperty('selected', true);
      }
    });
    if(editable) {
      map.data.addListener('mouseover', function (event) {
        map.data.revertStyle();
        map.data.overrideStyle(event.feature, {strokeWeight: 4});
        let m = event.feature.getProperty('marker');
        mapHelper.setMarkerSelected(event.feature.getProperty('marker'), map);
      });
      map.data.addListener('click', function (event) {
        let selected = event.feature.getProperty('selected')
        event.feature.setProperty('selected', !selected);
        $.ajax({
          url: url,
          method: "PUT",
          data: {pro_com: event.feature.getProperty('PRO_COM')}
        });
      });
      map.data.addListener('mouseout', function (event) {
        map.data.revertStyle();
        mapHelper.setMarkerNormal(event.feature.getProperty('marker'), map);
      });
    }

    // carico i dati dal file geojson che sta in public
    map.data.loadGeoJson('/territorio_tribunale_pn.geojson');


    // definisco gli stili dei poligoni dei comuni, ATTENZIONE: questo metodo viene chiamato ogni volta che si modifica
    // una proprietà (feature.setProperty(...))
    map.data.setStyle(function(feature) {
      let fillColor = '#fefefe';
      let strokeWeight = 1;
      if(feature.getProperty('selected')){
        fillColor = 'green';
        strokeWeight = 2;
      }else{
        fillColor = '#fefefe';
        strokeWeight = 1;
      }
      return /** @type {google.maps.Data.StyleOptions} */({
        fillColor: fillColor,
        strokeWeight: strokeWeight,
        fillOpacity: 1,
        cursor: editable ? 'pointer': 'grab'
      });
    });

  }
}

const mapHelper = {
  addMarker: function(location, map, label) {
    // Add the marker at the clicked location, and add the next-available label
    // from the array of alphabetical characters.
    var marker = new google.maps.Marker({
      position: location,
      label: {
        text: label,
        color: 'black',
        fontSize: '12px'
      },
      map: map,
      icon: {
        path: google.maps.SymbolPath.CIRCLE, //or any others
        scale: 0
      }
    });
    mapHelper.setMarkerNormal(marker, map);
    return marker
  },

  setMarkerNormal: function(marker, map){
    let markerLabel = marker.getLabel();
    markerLabel.fontSize = mapHelper.getMarkerLabelSize(map);
    markerLabel.fontWeight = 'normal';
    markerLabel.color = 'black';
    marker.setLabel(markerLabel);
  },

  setMarkerSelected: function(marker, map){
    let markerLabel = marker.getLabel();
    markerLabel.fontWeight = 'bold';
    markerLabel.fontSize = '40px'
    markerLabel.color = 'red';
    marker.setLabel(markerLabel);
  },

  getMarkerLabelSize: function(map){
    switch (map.getZoom()) {
      case 9:
        return '8px'
      case 10:
        return '12px'
      case 11:
        return '16px'
      case 12:
        return '20px'
      case 13:
        return '24px'
    }
    return '12px'
  }
}

export default app;
