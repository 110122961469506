import "bootstrap";
import "jquery-mousewheel";
import "jquery-ui-sortable";
import "malihu-custom-scrollbar-plugin";
import Rails from "rails-ujs";
import * as ActiveStorage from "activestorage";
import "./cocoon";
import 'flatpickr';
import ReactOnRails from 'react-on-rails';

import HelloWorld from "./components/HelloWorld";
import MyApp from "./app";

window.MyApp = MyApp;

Rails.start();
ActiveStorage.start();
ReactOnRails.register({
  HelloWorld,
});

$(function() {
  MyApp.initOnLoad();
  $("#sidebar > .sidebar-content").mCustomScrollbar({
    theme: "minimal-dark",
    scrollInertia: 250,
    mouseWheel: {
      preventDefault: true,
    }
  });

  $('#sidebarCollapse').on('click', function () {
    $('#sidebar, .main').toggleClass('collapsed');
    $('.collapse.in').toggleClass('in');
    $('a[aria-expanded=true]').attr('aria-expanded', 'false');
  });

  $(document).on('change', '[data-autosubmit=change]', function(event) {
    $(this).closest('form').submit();
  });

  $('.sidebar-collapse').on('click', function() {
    $.ajax({
      method: 'POST',
      url: '/toggle_sidebar_mode',
      data: { collapsed: $('#sidebar').hasClass('collapsed') }
    })
  });

  $(document).on('cocoon:after-insert', function(event, insertedItem) {
    var $item = $(insertedItem)
    $item.find('[data-provide="flatpickr"]').each(function () {
      MyApp.initFlatPickr($(this))
    });
  });

  $(document).on('ajax:complete', function(xhr, status){
    MyApp.initOnLoad();
  });
});

